@import "react-tooltip/dist/react-tooltip.css";
@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Sohne Buch";
  src: url("/public/fonts/soehne-web-buch.woff2") format("woff2");
}

@font-face {
  font-family: "Sohne Halbfett";
  src: url("/public/fonts/soehne-web-halbfett.woff2") format("woff2");
}

@font-face {
  font-family: "Sohne Briet Extrafett";
  src: url("/public/fonts/soehne-breit-web-extrafett.woff2") format("woff2");
}

@font-face {
  font-family: "Sohne Mono Buch";
  src: url("/public/fonts/soehne-mono-web-buch.woff2") format("woff2");
}

@layer utilities {
  .h-50vh {
    height: 50vh;
  }
  .max-h-50vh {
    max-height: 50vh;
  }
  .max-h-90vh {
    max-height: 90vh;
  }
  .reveal-font-body {
    @apply font-body;
  }

  .reveal-font-title {
    @apply font-title;
  }

  .reveal-font-subTitle {
    @apply font-subTitle uppercase tracking-widest;
  }

  .reveal-font-display {
    @apply font-display uppercase tracking-wide;
  }

  .show-all-content {
    @apply whitespace-normal break-words;
  }

  /* Hide scrollbar for Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }
  /* Hide scrollbar for IE, Edge and Firefox */
  .no-scrollbar {
      -ms-overflow-style: none;  /* IE and Edge */
      scrollbar-width: none;  /* Firefox */
  }
}

p.terms-of-use {
  margin-bottom: 20px;
}

li.terms-of-use {
  margin-bottom: 16px;
}

h3.terms-of-use {
  font-weight: bold;
  margin-bottom: 6px;
}
